import { PageProps } from "gatsby";
import React from "react";

import SheetMusic from "../components/pages/SheetMusic/SheetMusic";

import { snProps } from "../js/utils";

const SheetMusicPage = (props: PageProps) => (
  <SheetMusic {...{ ...snProps, ...props }} />
);

export default SheetMusicPage;
